import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import Moment from "react-moment";  // For formatting date
import moment from "moment";  // For date manipulation

// Function to increase the date by 1 day
function increaseDateBy1(s, manual) {
    if (!manual) {
        var d = moment(s).toDate();
        var increasePlanDate = new Date(d.setDate(d.getDate() + 1));
        return increasePlanDate;
    } else {
        return s;
    }
}

const POdetails = () => {
    const [input, setInput] = useState('');
    const [selectedPlant, setSelectedPlant] = useState("All");
    const [data, setData] = useState([]);

    // Fetching combined PO details from the backend
    const fetchPODetails = async (poNumber) => {
        try {
            const response = await axios.get(`/api/poDetail?poNumber=${poNumber}`);
            setData(response.data.data.data);  // Update the state with combined data
        } catch (error) {
            console.error("Error fetching PO details:", error);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (input.trim()) {
            fetchPODetails(input);  // Fetch data when the search button is clicked
        }
    };

    return (
        <div className="container">
            <div className="row mb-3" style={{ marginTop: '100px' }}>
                <div className="col-md-6">
                    <form className="d-flex" onSubmit={handleSearch}>
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Search by PO Number..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            autoFocus
                        />
                        <button type="submit" className="btn btn-primary">
                            <i className="fa fa-search"></i>
                        </button>
                    </form>
                </div>
                <div className="col-md-6">
                    <select
                        className="form-control"
                        value={selectedPlant}
                        onChange={(e) => setSelectedPlant(e.target.value)}
                    >
                        <option value="All">All</option>
                        <option value="Plant A">Plant A</option>
                        <option value="Plant B">Plant B</option>
                        <option value="Plant C">Plant C</option>
                    </select>
                </div>
            </div>

            <h6 className="text-right text-primary"> <i className="fa fa-hashtag"></i>Selelcted PO: {data.length > 0 && data[0].poNumber}<br></br> <i className="fa fa-hashtag"></i>Count: {data.length}</h6>
            <div>
                <table className="table table-bordered table-hover mt-4 w-100">
                    <thead className="thead-dark">
                        <tr>
                            <th>PO Received date</th>
                            <th>Planned date</th>
                            <th>Picked date</th>
                            <th>EL Received date</th>
                            <th>Dispatch date</th>
                            <th>Delivery Date</th>
                            <th>Delivery type</th>
                            <th>Delivery Plant</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Moment format="DD-MM-YYYY">{increaseDateBy1(item.docDate, false)}</Moment>
                                </td>
                                <td>   <Moment format="DD-MM-YYYY">{increaseDateBy1(item.asnUploadDate, false)}</Moment></td>
                                <td>
                                    <Moment format="DD-MM-YYYY">{increaseDateBy1(item.inDate, false)}</Moment>
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY">{increaseDateBy1(item.inDate, false)}</Moment>
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY">{increaseDateBy1(item.transDate, false)}</Moment>
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY">{increaseDateBy1(item.transDate, false)}</Moment>
                                </td>
                                <td>
                                    {item.modeOfDelivery}
                                </td>
                                <td>
                                    {item.deliveryPlant}
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="9">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default POdetails;